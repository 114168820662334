import React, { useState } from "react"
import "../Themes.css"

import { Header, Footer } from "../../components/Sections"
import { MapsContainer, AcceptContainer } from "../../components/RHFComponents/live_components"
import onSubmitFactory from "./onSubmit"
import {
	InputFactory,
	InputFileFactory,
	GoogleAutoCompleteFactory,
	SelectAutoCompleteFactory,
	SSNControllerFactory,
	PhoneControllerFactory,
} from "../../components/RHFComponents"
import { filterPrescribers } from "../../services/api"

import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"

import schema from "../../schemas/record_no_order"
import { joiResolver } from "@hookform/resolvers/joi"

export default function FormNoOrder() {
	const navigate = useNavigate()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const {
		control,
		register,
		handleSubmit,
		setError,
		watch,
		formState: { errors },
	} = useForm({
		resolver: joiResolver(schema) /* mode: "onBlur" */,
	}) // to validate each time we leave a field.
	const Input = InputFactory(register, errors)
	const InputFile = InputFileFactory(register, control, errors)
	const GoogleAutoComplete = GoogleAutoCompleteFactory(control)
	const SelectAutoComplete = SelectAutoCompleteFactory(control)
	const SSNInput = SSNControllerFactory(control)
	const PhoneInput = PhoneControllerFactory(control)
	const onSubmit = onSubmitFactory(navigate, setIsSubmitting, setError)
	const {
		toggle_manual_billing_address,
		prescription,
		prescription_bis,
		toggle_prescription_bis,
		toggle_prescription_ter,
	} = watch()

	console.log(errors)
	return (
		<div>
			<Header />
			<div className="title-formula">Formulaire d’enregistrement des coordonnées et données de santé</div>
			<div className="under-title-formula">
				Vous venez d’être équipé, Activez la prise en charge du dispositif médical par la Sécurité Sociale et votre
				mutuelle en remplissant le formulaire ci-dessous.
			</div>

			<form className="form-big" onSubmit={handleSubmit(onSubmit)}>
				{Input("text", "firstname", "Prénom")}
				{Input("text", "lastname", "Nom")}
				{Input("date", "birth", "Date de Naissance", "Clicker sur la flèche pour ouvrir le calendrier")}
				{Input("email", "email", "Email")}
				{PhoneInput("phone", "Téléphone portable")}
				{
					<>
						{!toggle_manual_billing_address && (
							<>
								{GoogleAutoComplete(
									"address",
									"Adresse de facturation",
									"Si votre adresse est introuvable, vous pouvez la remplir manuellement"
								)}
								{Input("text", "manual_billing_additional_address_line", "Complément d'adresse")}
								<div>
									<label className="prescription-note">
										Votre adresse de facturation est introuvable ?
										<input type="checkbox" {...register("toggle_manual_billing_address")} />
									</label>
								</div>
							</>
						)}
						{toggle_manual_billing_address && (
							<div>
								<div className="prescription-note">Veuillez rentrer votre adresse de facturation manuellement</div>
								{Input("text", "manual_billing_address", "Adresse Facturation")}
								{Input("text", "manual_billing_additional_address_line", "Complément d'adresse")}
								{Input("text", "manual_billing_postal_code", "Code Postal")}
								{Input("text", "manual_billing_city", "Ville")}
							</div>
						)}
					</>
				}
				<div className="arange-map">
					<MapsContainer control={control} />
				</div>
				{SSNInput("social_security", "Numéro de Sécurité Sociale")}
				{Input(
					"number",
					"amc",
					"Numéro AMC ou de télétransmission",
					"Le numéro AMC parfois appelé numéro de télétransmission est le numéro d’identification de votre mutuelle. Vous pouvez l'obtenir sur votre carte de mutuelle et sur votre compte Ameli.fr"
				)}
				{InputFile(
					"mutual_photo",
					"Vous pouvez prendre en photo et/ou deposer votre carte mutuelle, avec le nom de la mutuelle et le numéro AMC (ou le numéro de télétransmission) bien visible",
					"Photo de la carte mutuelle"
				)}
				{/*Input(
					"text",
					"mutual",
					"Mutuelle",
					"Le nom de votre Mutuelle Santé est située sur votre carte de mutuelle et sur votre compte Ameli.fr dans la rubrique 'complémentaire santé'"
				)} */}
				{SelectAutoComplete(
					"prescriber",
					filterPrescribers,
					"Prescripteur",
					"Ici vous rentrez le nom du médecin ayant délivré votre ordonnance. Si il n'est pas dans les options, ne rentrez rien."
				)}
				{/* {Input(
					"text",
					"size",
					"Si possible, précisez la taille de l'attelle",
					"Vous pouvez ici donner votre pointure (42) ou alors la taille de l'attelle (S,M,L)"
				)} */}
				{InputFile(
					"prescription",
					"Vous pouvez prendre en photo et/ou deposer votre ordonnance. Important : si vous ne parvenez pas à joindre le fichier, basculez sur le navigateur Google Chrome",
					"Ordonnance"
				)}
				{prescription && prescription.length > 0 && (
					<div
						className="text_toggle"
						style={{
							display: toggle_prescription_bis ? "none" : "block", // toggle the visbility of an input
						}}
					>
						Voulez-vous déposer une deuxième ordonnance ?
						<input type="checkbox" name="toggle_prescription_bis" {...register("toggle_prescription_bis")} />
					</div>
				)}
				{toggle_prescription_bis &&
					InputFile(
						"prescription_bis",
						"Vous pouvez prendre en photo et/ou deposer votre ordonnance. Important : si vous ne parvenez pas à joindre le fichier, basculez sur le navigateur Google Chrome",
						"Ordonnance"
					)}
				{prescription_bis && prescription_bis.length > 0 && (
					<div
						className="text_toggle"
						style={{
							display: toggle_prescription_ter ? "none" : "block", // toggle the visbility of an input
						}}
					>
						Voulez-vous déposer une troisième ordonnance ?
						<input type="checkbox" name="toggle_prescription_ter" {...register("toggle_prescription_ter")} />
					</div>
				)}
				{toggle_prescription_ter &&
					InputFile(
						"prescription_ter",
						"Vous pouvez prendre en photo et/ou deposer votre ordonnance. Important : si vous ne parvenez pas à joindre le fichier, basculez sur le navigateur Google Chrome",
						"Ordonnance"
					)}

				<div className="accept-bulletin-flex">
					{Input("checkbox", "accept", "", "", "", "", "", "error")}
					<AcceptContainer control={control} />
				</div>
				<input className="button-submit-patient" type="submit" value="Envoyer" disabled={isSubmitting} />
				<div className="final-error"> {Object.keys(errors).length > 0 && "Vérifiez les champs requis"}</div>
			</form>
			<Footer route="/commande" text="Commander une attelle" />
		</div>
	)
}
