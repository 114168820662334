import Joi from "joi"

export default Joi.object({
	firstname: Joi.string().min(1).max(30).required().messages({
		"string.base": `Le prénom doit être un texte`,
		"string.empty": `Saisissez votre prénom`,
		"string.min": `Le prénom doit avoir au moins {#limit} lettres`,
		"string.max": `Le prénom doit avoir au plus {#limit} lettres`,
		"any.required": "Saisissez votre prénom",
	}),

	lastname: Joi.string().min(1).max(30).required().messages({
		"string.base": `Le nom doit être un texte`,
		"string.empty": `Saisissez votre nom de famille`,
		"string.min": `Le nom doit avoir au moins {#limit} lettres`,
		"string.max": `Le nom doit avoir au plus {#limit} lettres`,
		"any.required": "Saisissez votre nom de famille",
	}),

	birth: Joi.date().max("now").required().messages({
		"date.base": `Saisissez votre date de naissance`,
		"date.max": `Votre date de naissance ne doit pas dépasser la date d'aujourd'hui.`,
		"any.required": "Saisissez votre date de naissance",
	}),

	email: Joi.string()
		.email({ minDomainSegments: 2, tlds: { allow: ["com", "fr", "io"] } })
		.messages({
			"string.base": `L'email n'a pas le bon format (un texte)`,
			"string.empty": `Saisissez votre email`,
			"string.email": `L'email n'a pas le bon format (.com ou .fr)`,
			"any.required": "Saisissez votre email",
		}),

	phone: Joi.string()
		.pattern(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i)
		.required()
		.messages({
			"string.base": `Le numéro de telephone n'a pas le bon format`,
			"string.empty": `Saisissez votre numéro de téléphone`,
			"string.pattern.base": `Seuls les numéro français sont acceptés`,
			"any.required": "Saisissez votre numéro de téléphone",
		}),

	shipping_address: Joi.any(),
	manual_shipping_address: Joi.string().allow(""),
	manual_shipping_postal_code: Joi.string().allow(""),
	manual_shipping_city: Joi.string().allow(""),
	manual_shipping_additional_address_line: Joi.string().allow(""),
	// .invalid(null, {}).required().messages({
	// 	"any.invalid": `Saisissez votre adresse de facturation`,
	// 	"any.required": `Saisissez votre adresse de facturation`,
	// }),
	//manual_shipping address/postal_code/city or shipping_address required
	// address or manual_billing
	address: Joi.any(),
	manual_billing_address: Joi.string().allow(""),
	manual_billing_postal_code: Joi.string().allow(""),
	manual_billing_city: Joi.string().allow(""),
	manual_billing_additional_address_line: Joi.string().allow(""),
	social_security: Joi.string()
		.pattern(
			/^[1-478][0-9]{2}(0[1-9]|1[0-2]|62|63)(2[ABab]|[0-9]{2})(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$/i
		)
		.required()
		.messages({
			"string.base": `Le numéro de sécurité social n'a pas le bon format`,
			"string.empty": `Saisissez votre numéro de sécurité social`,
			"string.pattern.base": `Le numéro de sécurité social n'a pas le bon format`,
			"any.required": "Saisissez votre numéro de sécurité social",
		}),

	amc: Joi.string().allow("").max(15).messages({
		"string.base": `Le numéro AMC doit être un texte`,
		"string.empty": `Saisissez votre numéro AMC`,
		"string.max": `Le prénom doit avoir au plus {#limit} caractères`,
		"any.required": "Saisissez votre numéro AMC",
	}),

	/* mutual: Joi.string().max(15).required().messages({
		"string.base": `Le nom de mutuelle doit être un texte`,
		"string.empty": `Saisissez le nom de votre mutuelle`,
		"string.max": `Le nom de votre mutuelle doit avoir au plus {#limit} caractères`,
		"any.required": "Saisissez le nom de votre mutuelle",
	}), */

	prescriber: Joi.any() /* .invalid(null, {}).required().messages({
		"any.invalid": `Saisissez le nom de votre prescripteur`,
		"any.required": `Saisissez le nom de votre prescripteur`,
	}) */,
	// size: Joi.string().allow(""),
	prescription: Joi.any(),
	prescription_bis: Joi.any(),
	prescription_ter: Joi.any(),
	mutual_photo: Joi.any(),
	surgery_date: Joi.alternatives().try(Joi.date().min("now"), Joi.valid("")).messages({
		"alternatives.any": `Le formulaire n'accepte pas les interventions passées`,
		"date.min": `Le formulaire n'accepte pas les interventions passées`,
		"date.base": `Le formulaire n'accepte pas les interventions passées`,
	}),
	mesure_ankle: Joi.number().min(0).allow(null, "").messages({
		"number.base": `Saisissez le tour de votre cheville en cm`,
		"number.empty": `Saisissez le tour de votre cheville en cm`,
		"number.min": `La valeur ne doit pas être négative`,
	}),
	mesure_thigh: Joi.number().min(0).allow(null, "").messages({
		"number.base": `Saisissez le tour de votre cuisse en cm`,
		"number.empty": `Saisissez le tour de votre cuisse en cm`,
		"number.min": `La valeur ne doit pas être négative`,
	}),
	mesure_leg: Joi.number().min(0).allow(null, "").messages({
		"number.base": `Saisissez la hauteur de votre jambe en cm`,
		"number.empty": `Saisissez la hauteur de votre jambe en cm`,
		"number.min": `La valeur ne doit pas être négative`,
	}),
	shoe_size: Joi.number().min(0).allow(null, "").messages({
		"number.base": `Saisissez votre pointure FR`,
		"number.empty": `Saisissez votre pointure FR`,
		"number.min": `La valeur ne doit pas être négative`,
	}),
	accept: Joi.string().valid(true).required().messages({
		"any.only": ``, // error in front outside input
		"any.required": ``, // error in front outside input
	}),
	toggle: Joi.string().allow(null, ""),
	toggle_manual_shipping_address: Joi.boolean(),
	toggle_manual_billing_address: Joi.boolean(),
	needMeasurements: Joi.string().allow(null, ""),
	needShoeSize: Joi.string().allow(null, ""),
	toggle_prescription_bis: Joi.boolean(),
	toggle_prescription_ter: Joi.boolean(),
})
